<template>
  <div>
    <CCard>
      <CCardHeader>
        Transaction List
      </CCardHeader>

      <CCardBody>
        <CDataTable
          hover
          :items="items"
          :fields="fields"
          :columnFilter='{ external: true, lazy: true }'
          :sorter='{ external: true }'
          @update:sorter-value="changeSort"
          @update:column-filter-value="changeFilter"
          :columnFilterValue.sync="currentFilters"
          :sorterValue="currentSort"
        >

          <template #type="{item}">
            <td>
              <span v-if="item.type =='CA-DEP'">Cashapp (Deposit)</span>
              <span v-if="item.type =='CA-WIT'">Cashapp (Withdrawal)</span>
              <span v-if="item.type =='BTC-DEP'">Bitcoin (Deposit)</span>
              <span v-if="item.type =='BTC-WIT'">Bitcoin (Withdrawal)</span>
              <span v-if="item.type =='CT-PSDEP'">Credit Transfer (Playstar Deposit)</span>
              <span v-if="item.type =='CT-PSWIT'">Credit Transfer (Playstar Withdrawal)</span>
              <span v-if="item.type =='CT-JKDEP'">Credit Transfer (Joker Deposit)</span>
              <span v-if="item.type =='CT-JKWIT'">Credit Transfer (Playstar Withdrawal)</span>
            </td>
          </template>

          <template #username="{item}">
            <td>
              {{ item.playerDetail.playerUsername }}
            </td>
          </template>

          <template #status="{item}">
            <td>
              <CBadge v-if="item.status == 'C'" color="success">Complete</CBadge>
              <CBadge v-if="item.status == 'PP'" color="info">Pending Payment / Processing</CBadge>
              <CBadge v-if="item.status == 'PV'" color="warning">Pending Verification</CBadge>
              <CBadge v-if="item.status == 'V'" color="secondary">Void</CBadge>
              <CBadge v-if="item.status == 'PC'" color="info">Pending Confirmation (BTC)</CBadge>
            </td>
          </template>

          <template #amount="{item}">
            <td>
              <span v-if="item.amount">{{ item.amount }}</span>
              <span v-if="!item.amount">-</span>
            </td>
          </template>

          <template #requestedAmount="{item}">
            <td>
              <span v-if="item.paymentDetail">{{ item.paymentDetail.requestedAmount }}</span>
              <span v-if="!item.paymentDetail">-</span>
            </td>
          </template>

          <template #show_details="{item, index}">
            <td class="py-2">
              <CButtonGroup>
                <CButton
                  v-if="item.status == 'PV'" 
                  color="success"
                  square
                  size="sm"
                  variant="outline"
                  @click="approveModalShow(item)"
                >
                  <CIcon name="cil-check-circle" />
                </CButton>
                <CButton
                  v-if="item.status == 'PV' || item.status =='PP'" 
                  color="danger"
                  square
                  size="sm"
                  variant="outline"
                  @click="voidModalShow(item)"
                >
                  <CIcon name="cil-x-circle" />
                </CButton>
              </CButtonGroup>
            </td>
          </template>

          <template #type-filter="{item}">
            <CSelect
              class="table-select"
              size="sm"
              :options="[ { value: null, label: 'All' }, { value: 'CT-PSWIT', label: 'Playstar (Withdrawal)' }, { value: 'CT-PSDEP', label: 'Playstar (Deposit)' }, { value: 'CA-DEP', label: 'Cashapp (Deposit)' }, { value: 'CA-WIT', label: 'Cashapp (Withdrawal)' } ]"
              @change="changeSelectFilter($event,'type')" 
              v-model="currentFilters.type"
            />  
          </template>

          <template #status-filter="{item}">
            <CSelect
              class="table-select"
              size="sm"
              :options="[ { value: null, label: 'All' }, { value: 'C', label: 'Complete' }, { value: 'PP', label: 'Pending Payment' }, { value: 'PV', label: 'Pending Verification' }, { value: 'V', label: 'Void' } ]"
              @change="changeSelectFilter($event,'status')" 
              v-model="currentFilters.status"
            />  
          </template>

        </CDataTable>
        <CPagination
          :activePage.sync="currentPage"
          :pages="pages"
          @update:activePage="changePage"
          align="center"
        />
      </CCardBody>
      <div class="justify-content-center table-spinner" v-show="isLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </CCard>

    <CModal
      title="Approve Transaction"
      color="success"
      :centered="true"
      :show.sync="approveModal"
      @update:show="approveTransaction"
    >
      <div class="table-responsive-sm" v-if="opItem">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Created</th>
              <td>{{opItem.createdAt}}</td>
            </tr>
            <tr>
              <th scope="row">Type</th>
              <td>
                <span v-if="opItem.type =='CA-DEP'">Cashapp (Deposit)</span>
                <span v-if="opItem.type =='CA-WIT'">Cashapp (Withdrawal)</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Username</th>
              <td class="caps-all">{{opItem.playerDetail.playerUsername}}</td>
            </tr>
            <tr>
              <th scope="row">Requested Amount</th>
              <td>${{opItem.paymentDetail.requestedAmount}}</CInput></td>
            </tr>
            <tr>
              <th scope="row">Sender (Cashtag)</th>
              <td class="caps-all">${{opItem.paymentDetail.senderCashtag}} ({{opItem.paymentDetail.senderCashtagName}})</td>
            </tr>
            <tr>
              <th scope="row">Receiver (Cashtag)</th>
              <td class="caps-all">${{opItem.paymentDetail.receiverCashtag}} ({{opItem.paymentDetail.receiverCashtagName}})</td>
            </tr>
            <tr>
              <th scope="row">Actual Amount</th>
              <td><CInput v-model="caActDepAmount" placeholder="Actual deposit amount" size="sm" required type="number">
                <template #prepend-content><CIcon name="cil-dollar"/></template>
              </CInput></td>
            </tr>
            <tr>
              <th scope="row">Remarks</th>
              <td><CInput v-model="caRemarks" placeholder="Transaction remarks" size="sm"></CInput></td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>

    <CModal
      title="Void Transaction"
      color="danger"
      :centered="true"
      :show.sync="voidModal"
      @update:show="voidTransaction"
    >
      <div class="table-responsive-sm" v-if="opItem">
        <table class="table">
          <tbody>
            <tr>
              <th scope="row">Created</th>
              <td>{{opItem.createdAt}}</td>
            </tr>
            <tr>
              <th scope="row">Type</th>
              <td>
                <span v-if="opItem.type =='CA-DEP'">Cashapp (Deposit)</span>
                <span v-if="opItem.type =='CA-WIT'">Cashapp (Withdrawal)</span>
              </td>
            </tr>
            <tr>
              <th scope="row">Username</th>
              <td class="caps-all">{{opItem.playerDetail.playerUsername}}</td>
            </tr>
            <tr>
              <th scope="row">Requested Amount</th>
              <td>${{opItem.paymentDetail.requestedAmount}}</CInput></td>
            </tr>
            <tr>
              <th scope="row">Sender (Cashtag)</th>
              <td class="caps-all">${{opItem.paymentDetail.senderCashtag}} ({{opItem.paymentDetail.senderCashtagName}})</td>
            </tr>
            <tr>
              <th scope="row">Receiver (Cashtag)</th>
              <td class="caps-all">${{opItem.paymentDetail.receiverCashtag}} ({{opItem.paymentDetail.receiverCashtagName}})</td>
            </tr>
            <tr>
              <th scope="row">Void Reason</th>
              <td><CSelect
                size="sm"
                :value.sync="caVoidCode"
                :options="voidOptions"
                placeholder="Select reason for void"
              /></td>
            </tr>
            <tr>
              <th scope="row">Remarks</th>
              <td><CInput v-model="caRemarks" placeholder="Transaction remarks" size="sm"></CInput></td>
            </tr>
          </tbody>
        </table>
      </div>
    </CModal>

  </div>
</template>

<script>
import apiTransaction from '@/api/transaction'

const items = [
]

const fields = [
  { key: 'username', _style:'min-width:80px' },
  { key: 'txnID', _style:'min-width:80px', sorter: false },
  { key: 'type', _style:'min-width:80px' },
  { key: 'status', _style:'min-width:50px;' },
  { key: 'amount', _style:'min-width:50px', label:'Actual Amount', filter: false },
  { key: 'requestedAmount', _style:'min-width:50px', label:'Requested Amount', filter: false },
  { key: 'createdAt', label: 'Created', _style:'min-width:50px;', filter: false },
  { key: 'updatedAt', label: 'Updated', _style:'min-width:50px;', filter: false },
  { 
    key: 'show_details', 
    label: '', 
    _style: 'width:1%', 
    sorter: false, 
    filter: false
  }
]

export default {
  name: 'ListTransaction',
  components: {
  },
  data () {
    return {
      isLoading: false,
      items,
      fields,
      pages: 0,
      currentPage: 1,
      currentSortColumn: null,
      currentSortOrder: null,
      currentFilters: {},
      currentSort: {},
      deleteModal: false,
      approveModal: false,
      voidModal: false,
      voidOptions: [
        { value: '0', label: 'Payment failed' },
        { value: '1', label: 'Deposit amount below minimum' },
        { value: '2', label: 'Deposited using different tag' },
        { value: '3', label: 'Non payment' }
      ],
      opItem: null,
      caActDepAmount: null,
      caRemarks: null,
      caVoidCode: null
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newVal) {
        console.log("query change")
        console.log(newVal)
        if(Object.keys(newVal).length == 0){
          this.currentPage = 1
          this.currentSortColumn = null
          this.currentSortOrder = null
          this.currentFilters = {}
        }else{
          if(newVal.page) { this.currentPage = parseInt(newVal.page) }
          if(newVal.sortColumn) { this.currentSortColumn = newVal.sortColumn }
          if(newVal.sortOrder) { this.currentSortOrder = parseInt(newVal.sortOrder) }
          if(newVal.filters) { this.currentFilters = JSON.parse(decodeURI(newVal.filters)) }
          if(newVal.sortColumn && newVal.sortOrder){
            this.currentSort = {column: newVal.sortColumn, asc: (newVal.sortOrder == 1) ? true : false }
            console.log(this.currentSort)
          }
        }
        this.fetchList(this.currentPage, 10, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      }
    }
  },
  created() {
  },
  methods: {
    fetchList(page, limit, sortColumn, sortOrder, filters) {
      this.isLoading = true;
      apiTransaction.list(page, limit, sortColumn, sortOrder, filters).then(response => {
        this.items = response.data.data
        this.pages = Math.ceil(response.data.count / 10)
        this.isLoading = false;
      }).catch(() => {
        
      })
    },
    changePage(val) {
      //this.fetchList(val, 10, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      this.currentPage = val
      this.$router.push({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeSort(val) {
      this.currentSortColumn = val.column
      this.currentSortOrder = (val.asc) ? 1 : -1;
      //this.fetchList(this.currentPage, 10, this.currentSortColumn, this.currentSortOrder, this.currentFilters)
      this.$router.push({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeSelectFilter(event, filter) {

      if(filter == 'status'){
        this.currentFilters['status'] = event.target.value
        if(!event.target.value){
         delete this.currentFilters['status'] 
        }
      }

      if(filter == 'type'){
        this.currentFilters['type'] = event.target.value
        if(!event.target.value){
         delete this.currentFilters['type'] 
        }
      }
      this.$router.push({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    changeFilter(val) {
      console.log(val);
      if(val.name == ''){
        delete this.currentFilters['name'] 
      }else{
        this.currentFilters['name'] = val.name
      }
      this.$router.push({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)) } })
    },
    approveModalShow(item){
      this.approveModal = true
      this.opItem = item
      this.caActDepAmount = item.paymentDetail.requestedAmount
    },
    approveTransaction(show, event, accepted){
      if(accepted){
        apiTransaction.approveCaTxn(this.opItem._id, this.caActDepAmount, this.caRemarks).then(response => {
          if (response.data.status === 'successful') {
            this.$router.replace({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), approveTxn: this.opItem._id+'approve' } })
            this.caActDepAmount = null
            this.caRemarks = null
            this.caVoidCode = null
          } else {
            alert("login failed");
          }
        }).catch((err) => {
            alert("login error");
            console.log(err);
        })
      }
    },
    voidModalShow(item){
      this.voidModal = true
      this.opItem = item
      this.caActDepAmount = item.paymentDetail.requestedAmount
    },
    voidTransaction(show, event, accepted){
      if(accepted){
        apiTransaction.voidCaTxn(this.opItem._id, this.caVoidCode, this.caRemarks).then(response => {
          if (response.data.status === 'successful') {
            this.$router.replace({ name: "List Transaction", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), approveTxn: this.opItem._id+'void' } })
            this.caActDepAmount = null
            this.caRemarks = null
            this.caVoidCode = null
          } else {
            alert("login failed");
          }
        }).catch((err) => {
            alert("login error");
            console.log(err);
        })
      }
    },
    /*
    deleteItemModalShow(id, name) {
      this.deleteModal = true
      this.opID = id
      this.opName = name
    },
    deleteItemConfirm(show, event, accepted) {
      if(accepted){
        apiGame.delete(this.opID).then(response => {
          if (response.data.status === 'successful') {
            alert("Successful deletion")
            this.$router.replace({ name: "List Game", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), deleted: this.opID } })
            this.opID = null
            this.opName = null
          }else{
            alert("Error in deletion")
          }
        }).catch((err) => {
          alert("login error");
          console.log(err);
        })
      }else{
        this.opID = null
        this.opName = null
      }
    },
    deactivateItemModalShow(id, name) {
      this.deactivateModal = true
      this.opID = id
      this.opName = name
      this.op = 'deactivate'
    },
    activateItemModalShow(id, name) {
      this.activateModal = true
      this.opID = id
      this.opName = name
      this.op = 'activate'
    },
    toggleStatusAdminConfirm(show, event, accepted) {
      if(accepted){
        var status = (this.op == 'deactivate') ? 1 : 0
        apiGame.update(this.opID, '', status).then(response => {
          if (response.data.status === 'successful') {
            //alert("Successful suspend")
            this.$router.replace({ name: "List Game", query: { page: this.currentPage, sortColumn: this.currentSortColumn, sortOrder: this.currentSortOrder, filters: encodeURI(JSON.stringify(this.currentFilters)), toggleSuspend: this.opID + '' + status } })
            this.opID = null
            this.opName = null
            this.op = null
          }else{
            alert("Error in suspend")
          }
        }).catch((err) => {
          alert("login error");
          console.log(err);
        })
      }else{
        this.opID = null
        this.opName = null
        this.op = null
      }
    }
    */
  }
}
</script>
